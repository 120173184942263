
































































































































































.user-form {
  &__roles {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -8px;
  }

  &__role {
    flex-basis: calc(50% - 16px);
  }
}
