


















































































































































































.settings {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 100%;
  overflow: hidden;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px 32px 0;

    &-text {
      display: flex;
      align-items: center;
    }
  }

  &__title {
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    color: #333333;
  }

  &__navigation {
    margin: 0 24px;
  }

  &__content {
    border-top: 1px solid #e7e7e7;
    // margin-right: 34px;
  }
}
